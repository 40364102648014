import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import Layout from '../../components/layout';
import { SoftwareItem, } from '../../types/software';
import { SoftwareList } from '../../components/softwareui';
import * as DataStructures from '../../types/software';
interface SoftwareProps { };
interface SoftwareState { };

const SOFTWARE_ITEMS: SoftwareItem[] = [
    DataStructures.LINKED_LIST
]

class Software extends React.Component<SoftwareProps, SoftwareState> {
    constructor(props: SoftwareProps) {
        super(props);
    }

    render() {
        return (
            <Layout>
                <SoftwareList items={SOFTWARE_ITEMS} />
            </Layout>
        );
    }
}

export default Software;
